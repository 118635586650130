import React from 'react';
import './Projects.scss';
import { InView } from 'react-intersection-observer';

const Projects = (props) => {
  const data = props?.data
  return (
    <div className='Projects'>
      <h2 dangerouslySetInnerHTML={{ __html: data.headline }} />
      <InView triggerOnce={true}>
        {({ inView, ref }) => (
          <div className='Projects__Wrapper' ref={ref}>
            {data.elements.map((item, i) => {
              return (
                <div key={i} className={`item item-${i + 1} ${inView ? 'fadeIn' : 'fadeOut'}`}>
                  <h3>{item.headline}</h3>
                  <p dangerouslySetInnerHTML={{ __html: item.text }} />
                </div>
              )
            })}
          </div>
        )}
      </InView>

    </div>
  );
}

export default Projects;