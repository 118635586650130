import React from 'react';
import './DownButton.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

const DownButton = (props) => {
    return (
      <button className='DownButton' onClick={props.func}>
        <FontAwesomeIcon icon={faChevronDown} size="lg" />
      </button>
    );
  }
  
  export default DownButton;