import React from 'react';
import './Services.scss'
import { InView } from 'react-intersection-observer';

const Services = (props) => {
  const data = props?.data
  return (
    <div className='Services'>
      <div className='Services__Portfolio' tabIndex="0">
        <h2 dangerouslySetInnerHTML={{ __html: data.portfolio.title }} />
        <div className='details'>
          <InView triggerOnce={true}>
            {({ inView, ref }) => (
              <div className={`info ${inView ? 'fadeIn' : 'fadeOut'}`} ref={ref}>
                <div className='info__main info__main--portfolio' dangerouslySetInnerHTML={{ __html: data.portfolio.infographic.main }} />
                {data.portfolio.infographic.elements.map((item, i) => {
                  return (
                    <div key={i} className={`info__item info__item--portfolio info__item--${i + 1}`} dangerouslySetInnerHTML={{ __html: item }} />
                  )
                })}
              </div>
            )}
          </InView>

        </div>
      </div>
      <div className='Services__Difference' tabIndex="1">
        <h2 dangerouslySetInnerHTML={{ __html: data.difference.title }} />
        <div className='details'>
          <InView triggerOnce={true}>
            {({ inView, ref }) => (
              <div className={`info info--2 ${inView ? 'fadeIn' : 'fadeOut'}`} ref={ref}>
                <div className='info__main' dangerouslySetInnerHTML={{ __html: data.difference.infographic.main }} />
                {data.difference.infographic.elements.map((item, i) => {
                  return (
                    <div key={i} className={`info__item info__item--${i + 1}`} dangerouslySetInnerHTML={{ __html: item }} />
                  )
                })}
              </div>
            )}
          </InView>

        </div>
      </div>
      <div className='Services__Core' tabIndex="2">
        <h2 dangerouslySetInnerHTML={{ __html: data.core.title }} />
        <div className='details'>
          <InView triggerOnce={true}>
            {({ inView, ref }) => (
              <div className={`info info--core info--3 ${inView ? 'fadeIn' : 'fadeOut'}`} ref={ref}>
                <div className='info__main' dangerouslySetInnerHTML={{ __html: data.core.infographic.main }} />
                {data.core.infographic.elements.map((item, i) => {
                  return (
                    <div key={i} className={`info__item info__item--core info__item--${i + 1}`} dangerouslySetInnerHTML={{ __html: item }} />
                  )
                })}
              </div>

            )}
          </InView>
          {data.core.list.map((item, i) => {
            return (
              <div key={i} className={`listitem-${i}`}>
                <h3 dangerouslySetInnerHTML={{ __html: item.headline }} />
                <p dangerouslySetInnerHTML={{ __html: item.text }} />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
}

export default Services;