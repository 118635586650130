import React from 'react';
import './BackButton.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();
  const clickHandler = () => {
    navigate("/");
  }

  return (
    <button className='BackButton' onClick={clickHandler}>
      <FontAwesomeIcon icon={faChevronLeft} size="lg" />
    </button>
  );
}

export default BackButton;