import React from 'react';
import './Footer.scss';

const Footer = (props) => {
    const data = props?.data

    return (
      <div className='Footer'>
        <nav>
            <ul>
                {data?.map((item, i)=><li key={i}><a href={item.url}>{item.title}</a></li>)}
            </ul>
        </nav>
      </div>
    );
  }
  
  export default Footer;