import React from 'react';
import './Intro.scss';
import IntroImage from '../../assets/img/roese-derberater.jpg';
import Glasses from '../../assets/img/glasses.jpg';
import DownButton from '../DownButton/DownButton'

const Intro = (props) => {

  const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  }
  window.addEventListener('resize', appHeight)
  appHeight()

  const data = props?.data
  return (
    <div className='Intro'>
      <div className='Intro__Left'>
        <img src={IntroImage} className="Intro__Image" alt="" />
      </div>
      <div className='Intro__Right'>
        <h1 dangerouslySetInnerHTML={{ __html: data.title }} />
        <h2 dangerouslySetInnerHTML={{ __html: data.subline }} />
        <img src={Glasses} className="Intro__Glasses" alt="" />
        <span className='claim'>{data.claim}</span>
      </div>
      <DownButton func={props.func} />
    </div>
  );
}

export default Intro;