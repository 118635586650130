import React from 'react';
import './Profile.scss';

const Profile = (props) => {
    const data = props?.data
    return (
      <div className='Profile'>
        <div className='Profile__Left'>
          <h2>{data.headline}</h2>
          <div
        dangerouslySetInnerHTML={{__html: data.text}}
      />
        </div>
        <div className='Profile__Right'>
          <h2>Kontakt</h2>
          <p><b>Telefon</b><br />+49 151 610 098 23</p>
          <p><b>E-Mail</b><br />carsten@roese-derberater.de</p>
        </div>
      </div>
    );
  }
  
  export default Profile;