import React, { useRef } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import './App.scss';
import BackButton from "./components/BackButton/BackButton";
import Footer from './components/footer/Footer';
import Intro from './components/intro/Intro';
import Profile from './components/profile/Profile';
import Projects from './components/projects/Projects';
import Services from './components/services/Services';
import data from './data.json';

function App() {
  const pageData = data.data
  const ref = useRef(null);

  const clickHandler = () => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  }

  const HeaderElement = () => {
    return (
      <div className="Header">
        <Link to="/"><span className="Header__Name">Carsten Roese - der Berater</span></Link>
        <Link to="/"><span className="Header__Title">Interim Manager &amp; Agile Coach</span></Link>
      </div>
    )
  }

  const LegalsContent = () => {
    return (
      <React.Fragment>
        <HeaderElement />
        <BackButton />
        <div className="content__wrapper">
          <h1>{pageData.pages.impressum.headline}</h1>
          <div dangerouslySetInnerHTML={{ __html: pageData.pages.impressum.text }} />
        </div>
      </React.Fragment>
    )
  }

  const PrivacyContent = () => {
    return (
      <React.Fragment>
        <HeaderElement />
        <BackButton />
        <div className="content__wrapper">
          <h1>{pageData.pages.datenschutz.headline}</h1>
          <div dangerouslySetInnerHTML={{ __html: pageData.pages.datenschutz.text }} />
        </div>
      </React.Fragment>
    )
  }

  const MainContent = () => {
    return (
      <React.Fragment>
        <Intro data={pageData.pages.intro} func={clickHandler} />
        <div ref={ref}><Services data={pageData.pages.services} /></div>
        <Profile data={pageData.pages.profil} />
        <Projects data={pageData.pages.projects} />
      </React.Fragment>
    );
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<MainContent />} />
          <Route path="/impressum" element={<LegalsContent />} />
          <Route path="/datenschutz" element={<PrivacyContent />} />
        </Routes>
      </BrowserRouter>
      <Footer data={pageData.footer} />
    </div>
  );
}

export default App;
